<template>
  <div class="team-profile-players">
    <div class="actions">
      <div v-if="MEMBER_POV" class="actions_title">Мои действия<span>
          <svg width="10" height="6" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 4.5L5 0.5L9 4.5" stroke="#E2FF31"/>
          </svg>
        </span></div>
      <div v-if="MEMBER_POV" class="actions_list">
        <div class="actions_list__item filled__grey" v-if="isUserCanEdit"
             @click="confirmOperation('delete_discipline')">Удалить дисциплину
        </div>
        <div v-if="isPlayerInCopmposition(USER)">
          <div class="actions_list__item filled__grey" v-if="canUserLeaveTeam"
               @click="confirmOperation('leave_discipline')">Покинуть дисциплину
          </div>
        </div>
        <div class="actions_list__item filled__grey" v-if="canUserLeaveTeam" @click="confirmOperation('leave_team')">
          Покинуть команду
        </div>
        <div class="actions_list__item filled__grey" v-if="isUserCanEdit" @click="confirmOperation('delete_team')">
          Расформировать команду
        </div>
      </div>
      <div v-if="!MEMBER_POV">
        <div class="filled__grey active-request__btn" v-if="ACTIVE_REQUEST">Ваша заявка на рассмотрении</div>
        <div v-else-if="haveNotificationsFromThisTeamPending" class="filled__yellow" @click="acceptRequestToTeam">
          Принять заявку
        </div>
        <div v-else-if="haveNotificationsFromThisTeamConfirmed"/>
        <div v-else class="filled__yellow" @click.self="sendRequestToTeam">Подать заявку</div>
      </div>
    </div>
    <div class="players-wrap">
      <composition-players-list @compositionHeight="provideCompositionHeight"/>
      <team-players-list v-if="isUserCanEditComposition" :composition="currentComposition" :height="heightPlayersList"/>
    </div>

    <div v-if="compositionEntries.length">
      <div class="requests" v-if="isUserCanEditComposition">
        <div class="count" v-if="!TEAM_ENTRIES_REQUEST">Заявки: {{ compositionEntries.length }}</div>
        <div class="requests-wrapper" v-if="!TEAM_ENTRIES_REQUEST">
          <team-profile-request-card
              v-for="(request, index) in compositionEntries" :key="request.id"
              :index="index"
              v-bind:is-flag="true"
          />
        </div>
      </div>
    </div>

    <confirmation-modal
        v-if="isConfirmationModal"
        accept-button-text="Да"
        decline-button-text="Ещё поиграем"
        :header-text="confirmationText"
        :success-call-back="successCallBack"
        @decline="closeConfirmModal"
        @closeModal="closeConfirmModal"/>
  </div>
</template>

<script>
import TeamProfileRequestCard from "@/components/TeamProfile/TeamProfileRequestCard";
import {mapActions, mapGetters} from 'vuex';
import CompositionPlayersList from "@/components/TeamProfile/TeamCompositions/composition-players-list";
import TeamPlayersList from "@/components/TeamProfile/TeamCompositions/team-players-list";
import {compositionMixin} from "@/otherJS/mixins/team-compositions-mixin";
import SimpleTextModal from "@/components/modals/simple-text-modal";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import acceptInviteToTeamWithEntry from "@/otherJS/acceptInviteToTeamWithEntry";

export default {
  name: "tc-tab",
  components: {TeamPlayersList, CompositionPlayersList, TeamProfileRequestCard, ConfirmationModal},
  mixins: [compositionMixin],
  props: {
    props: {}
  },
  data() {
    return {
      heightPlayersList: '',
      isConfirmationModal: false,
      confirmationText: 'Расформировать команду?',
      thanksModal: false,
      successCallBack: null,
    }
  },
  computed: {
    ...mapGetters([
      'USER',
      'TEAM_USERS',
      'TEAM_ENTRIES',
      'MANAGER_POV',
      'MEMBER_POV',
      'TEAM',
      'TEAM_GAMES',
      'ACTIVE_TEAM_TAB',
      'TEAM_ENTRIES_REQUEST',
      'ACTIVE_REQUEST',
      'GET_CURRENT_TEAM_FOR_COMPARE_NOTIFICATIONS',
      'USER_TEAMS',
      'GET_CAPTAIN_AND_MANAGED_TEAMS',
      'GET_IS_HAVE_ENTRIES_TEAM_TO_USER',
      'GET_ENTRY_DATA_FOR_TEAM_TO_USER',
      'GET_NOTIFICATIONS_TEAM_TO_USER'
    ]),
    isUserCanEdit() {
      if (this.MANAGER_POV) {
        if (this.TEAM.attributes.deletedAt === null) {
          return true
        }
      }
      return false
    },
    canUserLeaveTeam() {
      if (this.MEMBER_POV) {
        return !this.MANAGER_POV;
      }
      return false;
    },
    compositionEntries() {
      return this.TEAM_ENTRIES.filter(el => el.entrie.etc.game_id === this.currentComposition.game_id)
    },
    haveNotificationsFromThisTeamPending() {
      // return this.GET_CURRENT_TEAM_FOR_COMPARE_NOTIFICATIONS && this.USER_TEAMS.length !== 0
      return this.GET_IS_HAVE_ENTRIES_TEAM_TO_USER === 'pending'
    },
    haveNotificationsFromThisTeamConfirmed() {
      // return this.GET_CURRENT_TEAM_FOR_COMPARE_NOTIFICATIONS && this.USER_TEAMS.length !== 0
      return this.GET_IS_HAVE_ENTRIES_TEAM_TO_USER === 'confirmed'
    },

  },
  methods: {
    ...mapActions([
      'CREATE_TEAM_ENTRIE',
      'DELETE_TEAM',
      'UPDATE_TEAM_DATA',
      'DELETE_USER_FROM_TEAM',
      'CHANGE_DISCIPLINE_COMPOSITION',
      'SET_WARNING_MODAL_ACTIVE',
      'CLEAR_ENTRIES_TEAM_TO_USER',
      'GET_TEAM',
      'GET_USER_ENTRIESES_TO_TEAM',
      'GET_SINGLE_USER',
      'SET_IS_HAVE_ENTRIES_TEAM_TO_USER',
      'SET_SHOW_THANKS_ACTION'
    ]),
    provideCompositionHeight(height) {
      this.heightPlayersList = height
    },

    sendRequestToTeam() {
      if (!this.USER_TEAMS.length && !this.GET_CAPTAIN_AND_MANAGED_TEAMS.length) {

        this.CREATE_TEAM_ENTRIE();
        this.SET_SHOW_THANKS_ACTION({
          configForSimpleTextModal: {
            text: 'Ваша заявка успешно подана!',
            status: 'default',
            timeoutSeconds: 3,
          },
          isSimpleModalOpen: true
        });

        return
      }

      this.SET_SHOW_THANKS_ACTION({
        configForSimpleTextModal: {
          text: 'Вы не можете подать заявку в команду, поскольку уже состоите в одной команде',
          status: 'warning',
          timeoutSeconds: 6,
        },
        isSimpleModalOpen: true
      });
    },

    confirmOperation(operation) {
      switch (operation) {
        case 'delete_team':
          this.confirmationText = 'Удалить команду?';
          this.successCallBack = this.deleteTeam;
          break;
        case 'delete_discipline':
          this.confirmationText = 'Удалить дисциплину?';
          this.successCallBack = this.deleteDiscipline;
          break;
        case 'leave_team':
          if (this.currentUser.role === 'capitan') {
            this.configForSimpleTextModal = {
              text: 'Вы не можете покинуть команду. Сначала передайте полномочия капитана другому игроку.',
              status: 'warning',
              timeoutSeconds: 6
            }
            this.thanksModal = true;
            return
          }
          this.confirmationText = 'Покинуть команду?';
          this.successCallBack = this.leaveTeam;
          break;
        case 'leave_discipline':
          this.confirmationText = 'Покинуть дисциплину?';
          this.successCallBack = this.leaveDiscipline;
          break;
        default:
          break;
      }
      this.isConfirmationModal = true;
    },
    deleteTeam() {
      let data = {
        'teamId': this.TEAM.id,
        'attributes': {
          'deletedAt': true
        }
      }
      this.DELETE_TEAM(data);
      setTimeout(() => {
        document.location.reload()
      }, 500)
    },
    deleteDiscipline() {
      let games = [...this.TEAM_GAMES];

      let gamesToUpdate = games.filter(game => Number(game.id) !== Number(this.currentComposition.game_id)).map(game => {
        return {
          id: game.id,
          type: 'games',
        }
      })
      let dataToUpdateTeam = {
        type: 'teams',
        teamId: this.TEAM.id,
        attributes: this.TEAM.attributes,
        games: gamesToUpdate
      }
      this.UPDATE_TEAM_DATA(dataToUpdateTeam)
    },
    async leaveTeam() {
      await this.DELETE_USER_FROM_TEAM({team_id: this.TEAM.id, user_id: this.USER.id})
      await this.GET_SINGLE_USER(true)
    },
    leaveDiscipline() {
      let data = {
        operation: 'delete',
        user_id: this.USER.id,
        composition_id: this.currentComposition.id,
        method: 'DELETE'
      }
      this.CHANGE_DISCIPLINE_COMPOSITION(data).then(() => {
        document.location.reload();
      })
    },

    closeConfirmModal() {
      this.isConfirmationModal = false
      this.successCallBack = null
    },

    async acceptRequestToTeam() {

      try {
        // Если пользователь уже состоит в какой-то команде,
        // То пользователю выводится модальное окно
        // с требованием покинуть команду, чтобы
        // принять заявку от новой

        const url = this.$router.currentRoute.path;
        const entryId = this.GET_ENTRY_DATA_FOR_TEAM_TO_USER[0].id;
        const teamId = this.$router.currentRoute.params.id

        if (this.USER_TEAMS.length !== 0) {
          this.SET_WARNING_MODAL_ACTIVE()
        } else {
          // Если пользователь ни в какой команде не состоит, то принимаем заявку

          // TODO здесь такая штука, что по идее правильно придется получать ВСЕ уведомления
          //  чтобы это корректно работало
          await acceptInviteToTeamWithEntry(entryId)

          // Делаем конфиг для модального окна, что заявка принята успешна
          this.configForSimpleTextModal = {
            text: 'Ваша заявка успешно принята!',
            status: 'default'
          }

          this.thanksModal = true;

          let data = {
            url,
            teamId
          }

          // Обновляем данные по текущей команде,
          // т.к. мы приняли заявку и должны находиться в составе команды
          await this.GET_TEAM(data)
          await this.GET_USER_ENTRIESES_TO_TEAM(teamId)
        }
      } catch (e) {

        // Если что-то пошло не так, например, не удалось получить данные с сервера,
        // то выводим соответствующую модалку с информацией
        this.configForSimpleTextModal = {
          text: 'Что-то пошло не так, попробуйте ещё раз!',
          status: 'warning'
        }
        this.thanksModal = true;

        throw new Error(`acceptRequestToTeam ${e}`)
      }
    },
  },

  mounted() {
    // Получаю уведомления, чтобы проверить, получал ли пользователь заявки конкретно от данный команды на вступление
    this.SET_IS_HAVE_ENTRIES_TEAM_TO_USER(this.$router.currentRoute.params.id)
  },
  destroyed() {
    this.CLEAR_ENTRIES_TEAM_TO_USER()
  }
}
</script>

<style scoped lang="scss">

</style>
