<template>
  <div class="content-block no-bg" v-if="!TEAM_REQUEST">
    <div class="content-block__title">О команде</div>
    <div class="edit" :class="{text_yellow: isEdit}" v-if="isUserCanEdit">
      <div v-if="!isEdit" @click="editOrSaveData">Редактировать</div>
      <div v-else @click="updateTeamData">Сохранить изменения</div>
    </div>
    <div class="content-block__content">
      <div class="content-block__string mb15">
        <div class="s1">Название</div>
        <label class="s2">
          <input ref="name" type="text" placeholder="Придумайте название команды" v-model="team.name"
                 :disabled="!isEdit">
        </label>
      </div>
      <div class="content-block__string mb15">
        <div class="s1">Тег</div>
        <label class="s2">
          <input ref="name" type="text" placeholder="Укажите тег команды" v-model="team.tag" :disabled="!isEdit">
        </label>
      </div>
      <div class="content-block__string mb15">
        <div class="s1">Играет в</div>
        <label class="s2">
          <Multiselect
              :disabled="!isEdit"
              ref="games"
              :options="team.games.options"
              v-model="team.games.value"
              track-by="name"
              label="name"
              :multiple="true"
              :searchable="false"
              :allowEmpty="false"
              placeholder="Выберите игру"
              :selectLabel=null
              :selectedLabel=null
              :deselectLabel=null
              :optionHeight="24"
              :close-on-select="false"
              open-direction="bottom">
            <template slot="tag" slot-scope="{ option }">
              <span class="custom__tag">
                <span>{{ option.name + concatWithComma(team.games.value, option.name) }}</span>
              </span>
            </template>
          </Multiselect>
        </label>
      </div>
      <div class="content-block__string mb15">
        <div class="s1">Страна</div>
        <label class="s2">
          <Multiselect
              :disabled="!isEdit"
              ref="country"
              :options="team.country.options"
              v-model="team.country.value"
              track-by="placeholder"
              label="placeholder"
              placeholder="Выберите страну"
              :searchable="false"
              :allow-empty="false"
              :selectLabel="null"
              :selectedLabel="null"
              :deselectLabel="null"
              open-direction="bottom"
          >
            <template slot="tag" slot-scope="{ option }">
                <span class="custom__tag">
                  <span>{{ option.placeholder }}</span>
                </span>
            </template>
          </Multiselect>
        </label>
      </div>
      <div class="content-block__string mb15">
        <div class="s1">Сайт</div>
        <label class="s2 website">
          <div v-if="!isEdit">
            <a :href="handleURL.href" target="_blank" v-if="team.site">{{ handleURL.host }}</a>
            <input v-else disabled placeholder="Укажите ссылку на сайт команды" readonly>
          </div>
          <input ref="site" type="text" placeholder="Ссылка на веб-сайт команды" v-model="team.site" :disabled="!isEdit"
                 v-if="isEdit">
        </label>
      </div>
      <!--соцсети команды, пока что отошли-->
      <!--<div class="content-block__string mb15">
        <div class="s1">Соцсети команды</div>
        <div class="s2 socials">
          <a target="_blank" :href="'//' + TEAM.attributes.Twitter">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M28 0H4C1.79086 0 0 1.79086 0 4V28C0 30.2091 1.79086 32 4 32H28C30.2091 32 32 30.2091 32 28V4C32 1.79086 30.2091 0 28 0Z"
                  fill="#00ABF1"/>
              <path
                  d="M28.5 8.24675C27.5802 8.65492 26.5921 8.93037 25.5545 9.05449C26.613 8.41972 27.4264 7.41428 27.8096 6.21716C26.8186 6.80495 25.7206 7.23129 24.552 7.46144C23.617 6.46442 22.2835 5.8418 20.8087 5.8418C17.9759 5.8418 15.6792 8.13851 15.6792 10.971C15.6792 11.3728 15.7247 11.7641 15.8123 12.14C11.5493 11.9255 7.77035 9.88404 5.24057 6.78039C4.79898 7.53795 4.54613 8.41874 4.54613 9.35929C4.54613 11.1388 5.4513 12.7084 6.82768 13.6282C5.98731 13.6014 5.1966 13.3709 4.50455 12.9869C4.50429 13.008 4.50429 13.0296 4.50429 13.0512C4.50429 15.5362 6.27243 17.609 8.61851 18.081C8.18809 18.1976 7.7348 18.2607 7.26758 18.2607C6.93663 18.2607 6.61561 18.2277 6.30275 18.1684C6.95525 20.2057 8.84964 21.6888 11.0936 21.7302C9.33866 23.1062 7.12698 23.9262 4.72335 23.9262C4.30978 23.9262 3.90152 23.9019 3.5 23.8539C5.77037 25.3098 8.46629 26.159 11.3627 26.159C20.7967 26.159 25.9559 18.3435 25.9559 11.5658C25.9559 11.3429 25.9507 11.1219 25.9412 10.9018C26.9426 10.1785 27.8127 9.27551 28.5 8.24675Z"
                  fill="white"/>
            </svg>
          </a>
          <a target="_blank" :href="'//' + TEAM.attributes.Discord">
            <svg width="32" height="37" viewBox="0 0 32 37" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M32 3.76682V36.5714C27.3932 32.5004 28.8666 33.848 23.5153 28.8731L23.8641 32.2559H3.7485C1.68225 32.2559 0 30.5738 0 28.489V3.76682C0 1.68225 1.68216 0 3.7485 0H28.2513C30.3177 0 32 1.68225 32 3.76682Z"
                  fill="#8A9CFE"/>
              <path
                  d="M24.1581 10.4416C21.5249 8.46684 19.0198 8.52164 19.0198 8.52164L18.7638 8.81428C21.8724 9.76508 23.317 11.1365 23.317 11.1365C18.9733 8.75589 13.8709 8.75555 9.65753 10.6063C8.98088 10.9171 8.57864 11.1365 8.57864 11.1365C8.57864 11.1365 10.0964 9.69196 13.3879 8.74116L13.2051 8.52164C13.2051 8.52164 10.6999 8.46684 8.06672 10.4416C8.06672 10.4416 5.43359 15.2142 5.43359 21.1022C5.43359 21.1022 6.96968 23.7535 11.0107 23.8816C11.0107 23.8816 11.6873 23.0589 12.2359 22.3639C9.91353 21.669 9.03593 20.2063 9.03593 20.2063C9.3049 20.3944 9.74847 20.6387 9.78561 20.6634C12.872 22.392 17.2561 22.9582 21.1959 21.3034C21.8357 21.0657 22.549 20.7182 23.2987 20.2245C23.2987 20.2245 22.3844 21.724 19.9889 22.4005C20.5377 23.0953 21.1959 23.8816 21.1959 23.8816C25.237 23.7536 26.7912 21.1021 26.7912 21.1021C26.7912 15.2142 24.1581 10.4416 24.1581 10.4416ZM12.693 19.4017C11.669 19.4017 10.8278 18.4874 10.8278 17.3719C10.8278 16.2565 11.6507 15.3422 12.693 15.3422C13.7352 15.3422 14.5764 16.2565 14.5581 17.3719C14.5581 18.4874 13.7352 19.4017 12.693 19.4017ZM19.3673 19.4017C18.3433 19.4017 17.5021 18.4874 17.5021 17.3719C17.5021 16.2565 18.325 15.3422 19.3673 15.3422C20.4096 15.3422 21.2325 16.2565 21.2325 17.3719C21.2325 18.4874 20.4096 19.4017 19.3673 19.4017Z"
                  fill="#FDFDFD"/>
            </svg>
          </a>
          <a target="_blank" :href="'//' + TEAM.attributes.VK">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M26.4 0H5.6C2.50721 0 0 2.50721 0 5.6V26.4C0 29.4928 2.50721 32 5.6 32H26.4C29.4928 32 32 29.4928 32 26.4V5.6C32 2.50721 29.4928 0 26.4 0Z"
                  fill="#4489C8"/>
              <path
                  d="M4.80994 9.88501H8.64994C8.73022 9.88488 8.80863 9.90921 8.87473 9.95477C8.94084 10.0003 8.99149 10.0649 9.01994 10.14C9.44494 11.235 11.2099 15.52 12.8249 15.87C14.0249 15.87 13.5999 10.17 12.0749 10.17C11.6749 10.17 12.7849 9.46001 12.8599 9.43001C14.2771 9.04156 15.7727 9.04156 17.1899 9.43001C17.9549 9.75001 18.0849 10.645 18.1049 11.405C18.1249 12.165 17.2549 15.405 18.3999 15.755C19.8999 16.215 21.8299 11.51 22.2799 10.355C22.3092 10.2809 22.3602 10.2173 22.4262 10.1727C22.4923 10.1281 22.5702 10.1045 22.6499 10.105H26.9249C26.991 10.1048 27.0561 10.121 27.1144 10.152C27.1727 10.1831 27.2224 10.2282 27.259 10.2831C27.2957 10.3381 27.3181 10.4013 27.3244 10.467C27.3307 10.5328 27.3205 10.5991 27.2949 10.66C26.4144 12.6689 25.3586 14.5963 24.1399 16.42C24.0363 16.5705 23.9877 16.7521 24.0023 16.9342C24.0169 17.1163 24.0937 17.2879 24.2199 17.42C25.4999 18.79 28.9999 22.67 26.9999 22.67H22.6849C22.5734 22.6703 22.4631 22.6468 22.3614 22.6011C22.2596 22.5554 22.1688 22.4886 22.0949 22.405C21.4249 21.665 19.4749 19.605 18.5949 19.77C17.8649 19.905 17.7949 21.145 17.8299 21.955C17.841 22.164 17.7697 22.369 17.6313 22.526C17.493 22.683 17.2986 22.7796 17.0899 22.795C16.3249 22.84 15.2799 22.86 15.1849 22.855C12.1349 22.66 10.3299 20.625 8.60494 18.355C6.79801 15.9484 5.38847 13.2677 4.42994 10.415C4.41035 10.3546 4.40521 10.2905 4.41495 10.2278C4.4247 10.1651 4.44904 10.1056 4.48601 10.054C4.52299 10.0024 4.57157 9.96025 4.62784 9.9309C4.68411 9.90154 4.74648 9.88583 4.80994 9.88501Z"
                  fill="white"/>
            </svg>
          </a>
          <a target="_blank" :href="'//' + TEAM.attributes.Facebook">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M28.0904 0C30.2482 0 32 1.75188 32 3.90963V28.0904C32 30.2481 30.2481 32 28.0904 32H21.4361V19.9428H25.5986L26.3906 14.779H21.4361V11.4279C21.4361 10.0153 22.1282 8.63819 24.3474 8.63819H26.6V4.24194C26.6 4.24194 24.5556 3.89306 22.6011 3.89306C18.5206 3.89306 15.8536 6.36612 15.8536 10.8433V14.779H11.3178V19.9428H15.8536V32H3.90963C1.75188 32 0 30.2481 0 28.0904V3.90963C0 1.75188 1.75181 0 3.90963 0L28.0904 0Z"
                      fill="#1777F2"/>
                <path
                    d="M21.4347 19.9423V31.9995H15.8522V19.9423H11.3164V14.7785H15.8522V10.8428C15.8522 6.36564 18.5192 3.89258 22.5997 3.89258C24.5542 3.89258 26.5986 4.24145 26.5986 4.24145V8.6377H24.346C22.1268 8.6377 21.4347 10.0148 21.4347 11.4275V14.7785H26.3892L25.5972 19.9423H21.4347Z"
                    fill="white"/>
              </g>
            </svg>
          </a>
        </div>
      </div>
      <div v-if="isEdit">
        <div class="content-block__string mb15">
          <div class="s1">Facebook</div>
          <label class="s2">
            <input ref="fb" type="text" placeholder="Ссылка на facebook команды" :value="TEAM.attributes.Facebook">
          </label>
        </div>
        <div class="content-block__string mb15">
          <div class="s1">Vkontakte</div>
          <label class="s2">
            <input ref="vk" type="text" placeholder="Ссылка на vkontakte команды" :value="TEAM.attributes.VK">
          </label>
        </div>
        <div class="content-block__string mb15">
          <div class="s1">Discord</div>
          <label class="s2">
            <input ref="discord" type="text" placeholder="Ссылка на discord команды" :value="TEAM.attributes.Discord">
          </label>
        </div>
        <div class="content-block__string mb15">
          <div class="s1">Twitter</div>
          <label class="s2">
            <input ref="twitter" type="text" placeholder="Ссылка на twitter команды" :value="TEAM.attributes.Twitter">
          </label>
        </div>
      </div>-->
      <div class="content-block__string mb15">
        <div class="s1 top">Описание</div>
        <div class="s2 text-area">
          <p ref="description"
             :contenteditable="isEdit"
             v-html="team.description"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import Multiselect from 'vue-multiselect'

export default {
  name: "TeamProfileAbout",
  components: {Multiselect},
  props: {
    isManagerPOV: Boolean,
    isNewTeam: Boolean,
    inputsUnlocked: Boolean
  },
  data() {
    return {
      isEdit: false,
      isEditSocials: false,
      team: {
        name: null,
        tag: null,
        games: {
          value: null,
          options: []
        },
        site: null,
        description: null,
        country: {
          value: 'russia',
          options: [
            {value: "RU", placeholder: 'Россия'},
            {value: "US", placeholder: 'США'},
            {value: "EN", placeholder: 'Англия'}
          ]
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'TEAM',
      'TEAM_GAMES',
      'TEAM_REQUEST',
      'GAMES',
      'MANAGER_POV'
    ]),
    handleURL() {
      return new URL(this.team.site)
    },
    isUserCanEdit() {
      if (this.MANAGER_POV) {
        if (this.TEAM.attributes.deletedAt === null) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    ...mapActions([
      'UPDATE_TEAM_DATA',
      'GET_DISCIPLINES'
    ]),
    isURLContainHost(string) {
      let url;
      try {
        url = new URL(string);
      } catch (e) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
    },
    editOrSaveData: function () {
      this.isEdit = this.isEdit === false;
    },
    concatWithComma: function (array, elem) {
      return [...array].pop() === elem ? '' : ', ';
    },
    setTeamData() {
      this.team.name = this.TEAM.attributes.name
      this.team.tag = this.TEAM.attributes.tag
      this.team.site = this.TEAM.attributes.site
      this.team.description = this.TEAM.attributes.description
      this.team.games = {
        value: this.TEAM_GAMES,
        options: this.GAMES.map(item => {
          return {
            'id': item.id,
            'type': item.type,
            'name': item.attributes.name
          }
        })
      }
      this.team.country = {
        value: this.team.country.options.filter(option => this.TEAM.attributes.country === option.value)[0],
        options: [
          {value: "RU", placeholder: 'Россия'},
          {value: "US", placeholder: 'США'},
          {value: "EN", placeholder: 'Англия'}
        ]
      }
    },
    setGamesToSelect() {
      this.team.games.options = this.GAMES.map(item => {
        let game = {
          'id': item.id,
          'name': item.attributes.name,
          'type': item.type
        }
        return game;
      })
    },
    updateTeamData() {

      if (this.team.site !== null) {
        /*** Если чел не пытается отдать строку с пробелами ...*/
        if (this.team.site.replace(/\s+/g, '').length) {
          /**...проверяем есть в строке "http://" или "https://" ...*/
          if (!this.isURLContainHost(this.team.site)) {
            /**... и если нету - добавляем "https://"*/
            this.team.site = `https://${this.team.site}`
          }
        }
      }

      let data = {
        'attributes': {
          'name': this.team.name,
          'tag': this.team.tag,
          'site': this.team.site,
          'description': this.$refs.description.innerText,
          'country': this.team.country.value.value
        },
        'games': this.team.games.value,
        'teamId': this.TEAM.id
      }
      this.UPDATE_TEAM_DATA(data);
      this.isEdit = false
    }
  },
  created() {
    this.setTeamData();
    this.GET_DISCIPLINES().then(() => {
      this.setGamesToSelect()
    })
  },
  mounted() {
    this.isEdit = this.inputsUnlocked
  }
}
</script>


<style lang="scss" scoped>

</style>
