import { render, staticRenderFns } from "./TeamProfileInformation.vue?vue&type=template&id=8906b20a&scoped=true&"
import script from "./TeamProfileInformation.vue?vue&type=script&lang=js&"
export * from "./TeamProfileInformation.vue?vue&type=script&lang=js&"
import style0 from "./TeamProfileInformation.vue?vue&type=style&index=0&id=8906b20a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8906b20a",
  null
  
)

export default component.exports