<template>
  <div class="team-profile-page" v-if="!TEAM_REQUEST">
    <team-profile-header />
    <div class="container team-profile-container">
      <div class="team-profile-information">
        <team-profile-about :inputsUnlocked="inputsUnlocked"/>

        <team-compositions />

      </div>
    </div>
  </div>
  <profile-sceleton v-else />

</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {compositionMixin} from "@/otherJS/mixins/team-compositions-mixin";
import TeamProfileAbout from "@/components/TeamProfile/TeamProfileAbout";
import TeamProfileHeader from "@/components/TeamProfile/TeamProfileHeader";
import ProfileSceleton from "@/views/sceleton/profile-sceleton";
import TeamCompositions from "@/components/TeamProfile/TeamCompositions/team-compositions";


export default {
  name: "TeamProfileInformation",
  mixins: [compositionMixin],
  components: {
    TeamCompositions,
    ProfileSceleton,
    TeamProfileAbout,
    TeamProfileHeader
  },
  data() {
    return {
      inputsUnlocked: false
    }
  },
  computed: {
    ...mapGetters([
        'TEAM',
        'TEAM_REQUEST',
        'CAN_U_GET_DATA_AFTER_USER_AUTH'
    ]),
    canUGetTeam() {
      return this.CAN_U_GET_DATA_AFTER_USER_AUTH;
    }
  },
  watch: {
    canUGetTeam (newVal, oldVal) {
      if (newVal) {
        this.getTeamData();
      }
    }
  },
  methods: {
    ...mapActions([
        'GET_TEAM'
    ]),
    getTeamData() {
      let data = {
        url: this.$router.currentRoute.path,
        id: this.$router.currentRoute.params.id
      }
      this.GET_TEAM(data)
    }
  },
  mounted() {
    if (this.CAN_U_GET_DATA_AFTER_USER_AUTH) {
      this.getTeamData();
    }
  }
}
</script>

<style lang="scss" scoped>
.team-profile-page {
  margin-top: 64px;
  padding-bottom: 112px;
}

.team-profile-container {
  padding-top: 16px;

  .filled__yellow {
    max-width: 256px;
    width: 100%;
    margin: 0 auto;
  }
}

.team-buttons {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 16px;
  align-items: center;
  justify-content: center;
  @media (max-width: 525px) {
    flex-flow: row wrap;
  }

  & > * {
    max-width: 256px;
    width: 100%;
    margin-left: 8px;
    margin-right: 8px;
    @media (max-width: 525px) {
      max-width: unset;
      margin-left: 16px;
      margin-right: 16px;
      margin-bottom: 16px;
    }
  }
}

.team-profile-information {
  @media (max-width: 992px) {
    .content-block {
      padding: 31px 32px 37px;
    }
  }
  @media (max-width: 580px) {
    .content-block {
      padding: 31px 16px 37px;
    }
  }
}

@media (max-width: 580px) {
  .team-profile-container {
    max-width: 100vw;
  }
}
</style>