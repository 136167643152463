<template>
  <div>
    <tc-tabs />
  </div>
</template>

<script>
import TcTabs from "@/components/TeamProfile/TeamCompositions/tc-tabs";
export default {
name: "team-compositions",
  components: {TcTabs}
}
</script>

<style scoped>

</style>