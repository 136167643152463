<template>
  <div class="modal-bg" v-if="!TEAM_REQUEST">
    <div class="modal-body">
      <div class="modal-head">
        Добавить игру
        <svg @click="closeModal"
             width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M3 3L21 21M21 3L3 21" stroke="white"/>
        </svg>
      </div>
      <div class="modal-buttons">
        <multiselect
            v-model="games.value"
            placeholder="Выберите игру"
            label="name"
            track-by="name"
            :options="games.options"
            :option-height="40"
            :show-labels="false"
            :searchable="false"
            open-direction="bottom"
        >
          <template slot="singleLabel" slot-scope="props">
            <img class="option__image" :src="props.option.logo" alt="#"><span class="option__desc">
            <span class="option__title">{{ props.option.name }}</span></span>
          </template>

          <template slot="option" slot-scope="props">
            <img class="option__image" :src="props.option.logo" alt="#">
            <div class="option__desc"><span class="option__title">{{ props.option.name }}</span></div>
          </template>
        </multiselect>

        <div class="filled__grey" @click="create()">Создать команду</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import Multiselect from 'vue-multiselect';

export default {
  name: "add-composition-modal",
  components: {Multiselect},
  props: {},
  data() {
    return {
      games: {
        value: null,
        options: []
      }
    }
  },
  computed: {
    ...mapGetters([
      'TEAM_REQUEST',
      'TEAM_GAMES',
      'GAMES',
      'TEAM'
    ])
  },
  methods: {
    ...mapActions([
      'UPDATE_TEAM_DATA',
    ]),
    setInitialData() {
      let deselectedGames = this.GAMES.filter(el => this.TEAM_GAMES.findIndex(i => i.id === el.id) === -1);

      console.log({deselectedGames})

      this.games.options = deselectedGames.map(item => {
        return {
          'id': item.id,
          'name': item.attributes.name,
          'logo': item.logo,
          'type': item.type,
          'number_of_players': item.attributes.number_of_players,
          'number_of_substitute': item.attributes.number_of_substitute
        };
      })
    },


    closeModal() {
      this.$emit('close')
    },
    closeModalTimeout() {
      setTimeout(() => {
        this.closeModal()
      }, this.timeoutSeconds * 1000);
    },

    create() {
      let games = [...this.TEAM_GAMES];
      games.push(this.games.value)

      let gamesToUpdate = games.map(game => {
        return {
          id: game.id,
          type: 'games',
        }
      })
      let dataToUpdateTeam = {
        type: 'teams',
        teamId: this.TEAM.id,
        attributes: this.TEAM.attributes,
        games: gamesToUpdate
      }
      this.UPDATE_TEAM_DATA(dataToUpdateTeam)
    }
  },
  created() {
    this.setInitialData()
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
.multiselect {
  min-height: 40px;
  line-height: 40px;

  ::v-deep .multiselect__option {
    min-height: 40px;
  }

  ::v-deep .multiselect__select {
    top: 16px;
  }
}

.multiselect .multiselect__select {

}


.modal-bg {
  z-index: 900;
}

.modal-body {
  background-color: #3A3A3A;
  padding-left: 24px;
  padding-right: 24px;
  max-width: 375px;
  width: 100%;
}

.modal-buttons {
  padding-top: 32px;
  padding-bottom: 58px;

  & > * {
    margin-bottom: 0;
    max-width: 200px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }
}

.modal-head {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
  text-transform: uppercase;
  color: var(--tournament-color);
  position: relative;
  padding-top: 30px;
  padding-bottom: 27px;
  text-align: center;
  border-bottom: 1px solid #898989;

  svg {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 30px;
  }

}
</style>