import {makeElFirstInArrByRole} from "@/otherJS/makeElFirstInArrByRole";

export const compositionMixin = {
    computed: {
        isUserCanEditComposition() {
            switch (true) {
                case this.MANAGER_POV:
                    return true
                case this.currentUser?.role === 'capitan':
                    return true;
                default:
                    return false
            }
        },
        currentComposition() {
            // console.log(this.TEAM.compositions.find(el => Number(el.game_id) === Number(this.TEAM_GAMES[this.ACTIVE_TEAM_TAB].id)))
            return this.TEAM.compositions.find(el => Number(el.game_id) === Number(this.TEAM_GAMES[this.ACTIVE_TEAM_TAB].id));
        },
        compositionPlayers() {
            let players = [];
            let subs = [];
            for(let i = 0; i < this.currentComposition.players.length; i++) {
                for(let j = 0; j < this.TEAM_USERS.length; j++) {
                    let user = {...this.TEAM_USERS[j]};

                    if (Number(this.currentComposition.players[i].id) === Number(user.id)) {
                        if (this.currentComposition.players[i].role !== 'substitute') {
                            user.role = this.currentComposition.players[i].role
                            players.push(user)
                        } else {
                            user.role = 'substitute';
                            subs.push(user)
                        }
                    }
                }
            }

            for(let i = 0; i < this.TEAM_GAMES[this.ACTIVE_TEAM_TAB].number_of_players; i++) {
                if (players.length < Number(this.TEAM_GAMES[this.ACTIVE_TEAM_TAB].number_of_players)) {
                    players.push({empty: true})
                }
            }

            makeElFirstInArrByRole(players, 'capitan')

            for(let i = 0; i < this.TEAM_GAMES[this.ACTIVE_TEAM_TAB].number_of_players; i++) {
                if (subs.length < Number(this.TEAM_GAMES[this.ACTIVE_TEAM_TAB].number_of_substitute)) {
                    subs.push({empty: true})
                }
            }

            return {players, subs};
        },
        currentUser() {
            // console.log(this.currentComposition.players.find(el => Number(el.id) === Number(this.USER.id)))
            return this.currentComposition.players.find(el => Number(el.id) === Number(this.USER.id))
        }
    },
    methods: {
        updateComposition(user, role) {
            const getNumber = (whoseNumber) => {
                return this.TEAM_GAMES.find(el => Number(el.id) === Number(this.currentComposition.game_id))[whoseNumber];
            }
            let initialPlayers = {...this.compositionPlayers};
            let compositionPlayers = initialPlayers.players.filter(el => !el.empty);
            let compositionSubs = initialPlayers.subs.filter(el => !el.empty);

            let numberOfSubs = getNumber('number_of_substitute');
            let numberOfPlayers = getNumber('number_of_players')

            switch (role) {
                case 'substitute':
                    if (compositionSubs.length < numberOfSubs) {
                        compositionPlayers = compositionPlayers.filter(el => String(el.id) !== String(user.id))
                        compositionSubs.push({id: String(user.id), role: 'substitute'})
                    } else {
                        this.showMessage('Все резервные слоты уже заняты');
                        return
                    }
                    break;

                case 'player':
                    if (numberOfPlayers.length < numberOfPlayers) {
                        compositionSubs = compositionSubs.filter(el => String(el.id) !== String(user.id))
                        compositionPlayers.push({id: String(user.id), role: 'player'})
                    } else {
                        this.showMessage('Все основные слоты уже заняты');
                        return
                    }
                    break;

                case 'capitan':
                    compositionPlayers.map(el => {
                        if (el.role === 'capitan') {
                            el.role = 'player'
                        }
                        if (Number(el.id) === Number(user.id)) {
                            el.role = 'capitan'
                        }
                    })

                    this.isConfirmModal = false;
                    break;

                case 'new_user':
                    if (compositionPlayers.length < numberOfPlayers) {
                        compositionPlayers.push({id: String(user.id), role: 'player'})
                    } else if (compositionSubs.length < numberOfSubs) {
                        compositionSubs.push({id: String(user.id), role: 'substitute'})
                    } else {
                        this.showMessage();
                        return
                    }
                    break
            }

            let allUsers = compositionPlayers.concat(compositionSubs);
            let data = {
                users: allUsers.map(el => {return {'role': el.role, 'id': String(el.id)}}),
                composition_id: this.currentComposition.id,
                method: 'PATCH',
                operation: 'update',
                usersData: allUsers.map(el => {return {'type': 'users', 'id': String(el.id)}})
            }
            this.CHANGE_DISCIPLINE_COMPOSITION(data);
        },
        isPlayerInCopmposition(player) {
            for (let i = 0; i < this.currentComposition.players.length; i++) {
                if (Number(player.id) === Number(this.currentComposition.players[i].id)) {
                    return true
                }
            }
            return false
        },
    }
}