import {API} from "@/Vuex/axios-config";

const acceptInviteToTeamWithEntry = async (entryId) => {

    const accessToken = localStorage.getItem('userAccessToken');

    try {
        const response = await API({
            method: 'patch',
            url: `/entries/${entryId}`,
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            data: {
                data: {
                    id: String(entryId),
                    type: 'entries',
                    attributes: {
                        status: "confirmed",
                    },
                }
            }
        })

        return response.data

    } catch (e) {
        console.log('acceptInviteToTeamWithEntry', e);
        throw new Error(`acceptInviteToTeamWithEntry ${e}`)
    }
}

export default acceptInviteToTeamWithEntry