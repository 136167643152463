<template>
  <div class="tooltip-container" :style="getStyles">
    <slot name="tooltip-menu"></slot>
  </div>
</template>

<script>
export default {
  name: "Tooltip",
  props: {
    /**
     * Позиция тултипа. Массив из 2-х чисел.
     *
     * position[0] - x-position
     * position[1] - y-position
     * */
    position: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  computed: {
    getStyles() {
      if (!this.position.length) {
        console.log('Вы не задали позицию для тултипа.')
        return false;
      }

      return {left: `${this.position[0]}px`, top: `${this.position[1]}px`};
    }
  }
}
</script>

<style scoped>

</style>