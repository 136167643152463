import { render, staticRenderFns } from "./TeamProfileHeader.vue?vue&type=template&id=539a325c&scoped=true&"
import script from "./TeamProfileHeader.vue?vue&type=script&lang=js&"
export * from "./TeamProfileHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "539a325c",
  null
  
)

export default component.exports