export default {
    data() {
        return {
            isMax768: false
        }
    },
    methods: {
        mq768() {
            this.isMax768 = window.matchMedia('(max-width: 768px)').matches;
        },
    },
    mounted() {
        this.mq768()
    },
    created() {
        window.addEventListener('resize', this.mq768)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.mq768)
    },
}