<template>
  <div class="request-card"
       @mouseenter="isMax768 ? null : menuVisible() & getMenuHeight()"
       @mouseleave="isMax768 ? null : menuHidden() & getMenuHeight()"
       @click="isMenuShow = !isMenuShow; getMenuHeight()">
    <router-link :to="{name: 'another-profile', params: {id: currentUser.id}}" target="_blank" class="ava"><img :src="`${currentUser.attributes.logo}`" alt=""></router-link>
    <router-link :to="{name: 'another-profile', params: {id: currentUser.id}}" target="_blank" class="info">
      <div class="top">
        <country-flag v-if="isFlag" :country="currentUser.attributes.country" size="normal" rounded/>
        <div class="nickname">{{ currentUser.attributes.login ? currentUser.attributes.login : 'User' }}</div>
      </div>
      <div class="name">{{currentUser.attributes.firstName}} {{currentUser.attributes.lastName}}</div>
    </router-link>
    <div class="menu" ref="menu">
      <div class="menu-head">
        <div v-if="currentUser.attributes.statistics.length" class="block">
          <div class="s1">Призовые очки</div>
          <div class="s2">
            <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M8.49382 2.76628L10.5967 4.86916L8.46616 6.99969L10.5967 9.13024L8.49383 11.2331L6.36328 9.10257L4.23273 11.2331L2.12986 9.13024L4.26041 6.99969L2.12987 4.86915L4.23274 2.76628L6.36328 4.89682L8.49382 2.76628Z"
                  fill="#E2FF31"/>
            </svg>
            <span>14 590</span>
          </div>
        </div>
        <div v-else class="no-stats">Статистика отсутствует</div>
      </div>
      <div class="menu-body" v-if="currentUser.attributes.statistics.length">
        <div class="row">
          <div class="block">
            <div class="s1">Ранг</div>
            <div class="s2">
              <span>12</span>
            </div>
          </div>
          <div class="block">
            <div class="s1">Матчей</div>
            <div class="s2">
              <span>368</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="block">
            <div class="s1">Рейтинг</div>
            <div class="s2">
              <span>36 / 5294</span>
            </div>
          </div>
          <div class="block">
            <div class="s1">Наград</div>
            <div class="s2">
              <span>52</span>
            </div>
          </div>
        </div>
      </div>
      <div class="menu-foot">
        <div class="filled__yellow" @click="updateEntry('confirmed')">ПРИНЯТЬ ЗАЯВКУ</div>
        <div class="menu-decline" @click="updateEntry('canceled')">ОТКЛОНИТЬ</div>
      </div>
    </div>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag'
import mqMax768 from "@/otherJS/mediaQueryMixins/mqMax768";
import {mapGetters, mapActions} from 'vuex';

export default {
  name: "TeamProfileRequestCard",
  components: {CountryFlag},
  mixins: [mqMax768],
  props: {
    isFlag: Boolean,
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isMenuShow: false,
    }
  },
  computed: {
    ...mapGetters([
        'TEAM',
        'TEAM_ENTRIES'
    ]),
    currentUser() {
      return this.TEAM_ENTRIES[this.index];
    }
  },
  methods: {
    ...mapActions([
        'UPDATE_TEAM_ENTRY'
    ]),
    getMenuHeight() {
      let menu = this.$refs.menu;
      let menuHeight = this.isMenuShow === true ? menu.scrollHeight : 0;
      menu.style.maxHeight = menuHeight + 'px';
    },
    menuVisible() {
      this.isMenuShow = true
    },
    menuHidden() {
      this.isMenuShow = false
    },

    updateEntry(status) {
      let data = {
        entry_id: this.currentUser.entrie.id,
        user_id: this.currentUser.id,
        status,
        game_id: this.currentUser.entrie.etc.game_id,
        user: this.currentUser
      }

      try {
        this.UPDATE_TEAM_ENTRY(data)
      } catch (error) {
        console.log('update entry err', error)
      }

    }
  },
}
</script>

<style scoped>

</style>
