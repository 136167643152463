<template>
  <div class="team-compositions">
    <div class="tc-tabs">
      <div class="tc-tabs__btns"
           v-for="(btn, index) of TEAM_GAMES" :key="btn.name + index"
      >
        <div class="btn"
             :class="{active: ACTIVE_TEAM_TAB === index}"
             @click="changeTab(index, btn)"
        >
          <span><img :src="btn.logo" alt="#"><span>{{ btn.name }}</span></span>
        </div>
      </div>
      <media :query="{minWidth: 769}">
        <div v-if="MANAGER_POV" class="new_game" @click="addGame"><span>+ Добавить игру</span></div>
      </media>
    </div>

    <tc-tab :props="TEAM_GAMES[ACTIVE_TEAM_TAB]" />

    <add-composition-modal v-if="isAddGame" @close="isAddGame = false" />
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import TcTab from "@/components/TeamProfile/TeamCompositions/tc-tab";
import AddCompositionModal from "@/components/TeamProfile/TeamCompositions/add-composition-modal";
import Media from "vue-media";
export default {
  name: "tc-tabs",
  components: {AddCompositionModal, TcTab, Media},
  data() {
    return {
      activeTab: 0,
      activeGame: {},
      isAddGame: false
    }
  },
  computed: {
    ...mapGetters([
        'TEAM',
        'TEAM_GAMES',
        'MANAGER_POV',
        'ACTIVE_TEAM_TAB'
    ])
  },
  methods: {
    ...mapMutations([
        'SET_ACTIVE_TEAM_TAB'
    ]),

    changeTab(i) {
      this.SET_ACTIVE_TEAM_TAB(i);
    },
    addGame() {
      this.isAddGame = true;
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>